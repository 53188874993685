.container {
  position: relative; /* Changed from fixed to relative */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: start;
  gap: 10px;
  padding: 10px;
  max-width: calc(100% - 250px - 20px); /* Adjusting width for sidebar */
  margin-top: 64px; /* Adjust based on navbar height and desired space */
  margin-left: 250px; /* Sidebar width */
  overflow: auto;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .container {
    margin-left: 20px; /* Adjust for smaller screens */
    max-width: calc(100% - 40px); /* Adjust width accordingly */
  }
}
