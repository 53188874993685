.miniNav {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px; /* Increased padding for better spacing */
    background-color: #f9f9f9; /* Light and subtle background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-bottom: 0;
  }
  
  .miniNav button {
    padding: 8px 15px;
    border: none;
    background-color: #1677ff;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s; 
  }
