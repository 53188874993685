.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    overflow-y: auto; /* Enable vertical scrolling when necessary */
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}


.frame, .modalFrame {
    position: relative;
    background-color: #193855;
    padding: 30px;
    border-radius: 8px;
    color: white;
    width: 50%;
    max-width: 500px; /* Adjust as needed */
    max-height: 80vh; /* Maximum height before scrolling */
    overflow-y: auto; /* Enable scrolling within the frame */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.frame.modal-frame {
    width: auto; /* Adjust width as necessary */
    max-width: 600px; /* Set a maximum width appropriate for modals */
    margin: 0;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 10px;  /* Adjust as necessary */
    right: 10px; /* Adjust as necessary */
    border: none;
    background: transparent;
    color: rgba(0, 0, 0, 0.6);
    font-size: 30px;
    cursor: pointer;
}



.container {
    display: flex;
    justify-content: center;
    align-items: center; /* Align items to the top */
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-top: 20px; /* Optional: top padding for better spacing */
    padding-bottom: 20px; /* Optional: bottom padding for better spacing */
}

.frame {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    width: 50%;
    max-width: 500px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.3);
    margin: 0 auto; /* Keeps it centered horizontally */
}

.login {
    background-color: #193855;
    color: white;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.label {
    font-weight: bold;
}

.input_field, .select_field {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd; /* Lighter border */
    font-size: 16px; /* Larger font size */
}

.input_field:focus, .select_field:focus {
    border-color: #4a90e2; /* Highlight focus */
    outline: none;
}

.button {
    padding: 10px 15px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    /* text-transform: uppercase; */
}

.google {
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 100px;
    border-radius: 4px;
}

.google_img {
    margin-right: 10px;
    width: 40px;
}

.button:hover {
    background-color: #3a7bd5;
}

.text_wrapper {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}
