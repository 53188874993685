.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    padding: 0 20px; 
  }
  
  .productMessage {
    background-color: #f2f2f2;
    padding: 40px; 
    margin: 0; 
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    max-width: 600px; 
    width: 100%; 
  }
  
  .message {
    color: #333;
    font-size: 18px;
    margin: 20px 0; 
  }
  
  .link {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 12px 24px; 
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 16px; 
  }
  
  .link:hover {
    background-color: #0056b3;
  }
  