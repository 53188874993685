.popupBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContainer {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirmButton, .cancelButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirmButton {
    background-color: #0BACCF;
    color: white;
}

.cancelButton {
    background-color: #0BACCF;
    color: white;
}
