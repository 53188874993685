.loading_circle {
    /* Example styles for the loading circle */
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #0BACCF;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.overlay {
    position: fixed; /* or 'absolute' if you're just covering a specific area */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100; /* higher than the forms */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
