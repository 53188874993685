.container {
    position: relative; /* Changed from fixed to relative */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: start;
    /* gap: 10px; */
    /* padding: 10px; */
    padding-left: 20px;
    padding-right: 20px;
    max-width: calc(100% - 250px - 20px); 
    margin-top: 64px; 
    margin-left: 250px; 
    /* height: 90vh; */
    overflow: auto;
}
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .container {
      margin-left: 20px; 
      max-width: calc(100% - 40px); 
    }
}