@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }

.stepper_modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 35vw;
    min-width: 300px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
    animation-duration: 0.5s; /* Adjust duration as needed */
    animation-fill-mode: forwards;
}

.slideIn {
    animation-name: slideInFromRight;
}
  
.slideOut {
    animation-name: slideOutToRight;
}

.inlineContainer {
    display: flex;
    align-items: center; /* Adjust vertical alignment as needed */
}


.stepError {
    color: red;
}

.errorMessage {
    min-height: 30px; /* Adjust this value as needed */
    color: red; /* Style for error message */
    /* margin-bottom: 10px;  */
}


.errorIcon {
    color: white;  /* Icon color */
    background-color: red;  /* Background color */
    border-radius: 50%;
    padding: 1.5px;
}

.inactiveErrorIcon{
    color: white;  /* Icon color */
    background-color: gray;  /* Background color */
    border-radius: 50%;
    padding: 1.5px;
}
  
  

.stepperContainer {
    max-width: 600px;
    margin-left: 30px;
    margin-right: 20px;
}


.stepper_modal:focus{
    outline: none;
}
  
.stepperFormContainer {
    overflow-y: auto;
    flex-grow: 1; 
    width: 90%; 
    margin-top: 40px;
    margin-left: 30px;
    margin-right: auto;
}
  
.controlButtons {
    display: flex;
    justify-content: flex-end; 
    padding: 10px;
}

.backButtonContainer {
    margin-right: auto; /* Pushes the container to the left */
}

.closeButtonContainer {
    text-align: right;
    padding: 20px;
}

.closeButton {
    position: absolute;
    top: 0px; 
    right: 5px; 
    border: none;
    background: transparent;
    color: rgba(0, 0, 0, 0.6);
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
}

.buttonProgress {
    position: absolute;
    z-index: 10001;
}  
