.searchLabel {
  position: relative; /* Set as relative to anchor the suggestions list */
  display: flex;
  align-items: center;
  background-color: #fff;
  /* border: 2px solid #0066CC; */
  border-radius: 20px;
  padding: 8px;
  flex-grow: 1; 
  max-width: 30%;
}
  
  .searchInput {
    border: none;
    outline: none;
    margin-left: 10px; 
    width: 100%; 
    flex-grow: 1;
  }
  
  .searchIcon {
    display: block;
    height: 20px;
    width: 20px; 
  }
  
  .searchInput input[type="text"] {
    font-size: 16px;
    background-color: transparent;
    width: 100%;
}


.searchContainer {
  /* Keep your existing styles */
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.suggestionsList {
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  /* border:  solid #0066CC; */
  overflow: hidden;
  border-top: none;
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  position: absolute;
  width: 95%; /* Match the width of the searchLabel */
  top: 103%; /* Position it right below the searchLabel */
  left: calc(50% - 47.5%);
  z-index: 1000; /* Ensure it appears above other content */
}

.suggestionItem {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* For a subtle divider */
  color: black; /* Setting the text color to black */

}

.suggestionItem:hover,
.suggestionItem:focus {
  background-color: #e0e0e0;
  /* border-radius: 0 0 20px 20px; */
}