.resultView .ant-tabs-nav {
    padding-left: 20px !important; /* Adjust this value as needed */
  }
  
  .textContentView pre {
    white-space: pre-wrap; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word; /* Standard CSS property for word-wrap */
}
  
.rightToLeftText {
  direction: rtl; /* Right-to-left text direction */
}