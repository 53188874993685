.card {
  width: 180px; /* Adjust width as needed */
  border-radius: 10px;
  background-color: #0aaccf1f; /* Original background color */
  box-shadow: 0 0px 4px 0 rgba(0,0,0,0.5);
  padding: 15px;
  text-align: center;
  margin: 5px;
}

.cardHeader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.divider {
  border: 0;
  height: 1px;
  background-color: #ccc; /* Gray line */
  margin-bottom: 10px;
}

.iconContainer {
  margin-bottom: 10px;
}

.icon {
  width: 50px !important; /* Adjust size as needed */
  height: 50px;
}

.status {
  font-size: 16px;
  margin-bottom: 10px;
  color: green;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.actionIcon {
  cursor: pointer;
  width: 25px;
  height: 30px;
}

.actionIcon2 {
  cursor: pointer;
  width: 25px;
  height: 20px;
  margin-top: 5px;
}

.statusButton {
  font-size: 16px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #cccccc; /* Default background color */
  color: white;
}

.statusButton.ready {
  background-color: rgb(2, 153, 208);
}

.statusButton.notReady {
  background-color: rgb(201, 201, 201);
}