.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.loginForm {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.loginTitle {
    text-align: center;
    margin-bottom: 20px;
}

.inputGroup {
    margin-bottom: 15px;
}

.inputGroup label {
    display: block;
    margin-bottom: 5px;
}

.inputGroup input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.loginButton {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #0056b3;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.loginButton:hover {
    background-color: #00408b;
}
