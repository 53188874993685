.toast {
    position: fixed;
    bottom: 50px;
    right: 20px;
    padding: 15px 30px;
    background-color: #0BACCF; /* Green color for success */
    color: white;
    border-radius: 25px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.2);
    z-index: 1000;
    opacity: 0; /* Start with an invisible toast */
    transition: opacity 0.5s, bottom 0.5s; /* Smooth transition for fade-in, fade-out */
}

.fadeIn {
    opacity: 1;
    bottom: 60px; /* Slightly raise the toast during fade-in */
}

.fadeOut {
    opacity: 0;
    bottom: 50px; /* Lower the toast during fade-out */
}
