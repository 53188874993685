/* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #193855;
    padding: 10px 20px;
    color: white;
  } */

  .header {
    position: fixed; /* Or 'sticky' */
    top: 0;
    z-index: 1000; /* High z-index to ensure it stays on top */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #193855;
    padding: 10px 20px;
    color: white;
}

.addButton {

}

  
  .logo {
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
  }

  .org_name {
    font-weight: bold;
    font-size: 24px;
    margin-left: 15px;
  }

  .logo_image {
    width: 40px;
    height: 40px;
    justify-content: center;
  }

  .userContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }  
  
  .searchContainer {
    flex-grow: 1; 
    display: flex;
    justify-content: center;
    /* margin-left: 90px; */
  }
  
  .addButton {
    position: fixed !important;
    bottom: 16px !important; /* Adjust as needed */
    right: 16px !important; /* Adjust as needed */
    z-index: 1050 !important; /* Slightly higher than the navbar to ensure it's on top */
    background-color: #0BACCF !important;
    border: none !important;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer !important;
    font-size: 24px !important;
    color: white !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Removed margin-right as it's unnecessary for a fixed position element */
  }

  * {
    box-sizing: border-box;
  }
  


