.sidebar {
  position: fixed;
  top: 44px; /* Height of the navbar */
  left: 0;
  background-color: white;
  width: 250px;
  padding-top: 20px;    
  padding-right: 20px;  
  padding-bottom: 20px; 
  padding-left: 10px;   /* Reduced left padding */
  box-sizing: border-box;
  height: calc(100% - 44px); /* Adjusting for the navbar */
  z-index: 99;
}


  .menuItem {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #333;
    font-size: 15px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .icon {
    margin-right: 10px;
    width: 24px; 
    height: 24px;
    object-fit: contain; 
  }
      
  .badge {
    margin-left: auto;
    /* background-color: #009688;
    color: white; */
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 14px;
    height: 24px;
    display: flex;
    align-items: center;
  }

  .newDocument{
    border-radius: 0px 8px 0px 0px;
    background: #0BACCF;
    color: white;
  }

  .newDocument:hover{
    background: #0babcfd3;
  }

  .clicked , .menuItem:hover{
    background-color: rgba(11, 172, 207, 0.24);
    border-radius: 8px 8px 8px 8px;
  }

  .main_home {
    background-color: rgba(11, 172, 207, 0.24);
    border-radius: 0px 8px 0px 0px;
  }