.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: flex; /* Centers the pagination */
    min-height: 600px;
    height: 687px;
    margin-top: 20px;
    margin-left: 20px;
    width: 95%; /* Or any other width */
}

.ant-empty-normal .ant-empty-image {
    height: 453px;
}

.documents_table {
    flex-grow: 1; /* Allows the table to grow and fill the container */
    min-height: 500px; /* Keeps a minimum height */
    overflow: auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

/* Center Pagination */
.ant-pagination {
    display: flex !important;
    justify-content: center !important;
    margin-top: 20px !important;
}

/* Header Row */
.ant-table-thead > tr > th {
    background-color: #e6e6e6 !important; /* Light grey color for header */
}

/* Alternating Row Colors and Separating Lines */
.ant-table-tbody > tr > td {
    background-color: #ffffff !important; /* White rows */
    border-bottom: 1px solid #d9d9d9 !important; /* Grey lines to separate rows */
    cursor: pointer;
}

/* Hover Effect for Rows */
.ant-table-tbody > tr:hover > td {
    background-color: #cdf3f7db !important; /* Slightly darker shade on hover */
}

/* Editable Text Field in Description Column */
.ant-table-tbody .ant-table-cell-editable {
    background: transparent;
    border: none;
}

/* Styling for Editable Text Field on Hover */
.ant-table-tbody .ant-table-cell-editable:hover {
    background: #f5f5f5;
    cursor: text;
}

.ant-table-body {
    min-height: 400px; /* Set a minimum height */
    overflow-y: auto; /* Ensures scrollability */
}

/* Styling for Editable Text Field */
.editable-text-field {
    width: 100%;
    padding: 4px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.editable-text-field:focus {
    outline: none;
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Hover Effect for Editable Text Field */
.editable-text-field:hover {
    border-color: #40a9ff;
}

