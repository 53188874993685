.resultView .ant-tabs-nav {
    padding-left: 20px !important; /* Adjust this value as needed */
  }
  
.formGroup {
    margin-bottom: 15px;
    align-items: center;
    display: flex;
}

.formLabel {
    flex: 0 0 200px;
    white-space: nowrap;
    display: flex;
    margin-bottom: 5px;
    padding-left: 10px;
    margin-right: 10px;
}

.formInput {
    width: 50%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-left: 20px;
}

/* ResultView.module.css */

.nestedFormGroup {
    margin-left: 20px; 
}
  
.objectTitle {
    font-weight: bold;
    margin-bottom: 5px;
    padding-left: 10px;
    margin-right: 10px;
}
 
/* ResultView.module.css */

.formGroup, .nestedFormGroup {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    margin-bottom: 15px;
  }
  
  .nestedFormGroup .formLabel {
    flex: 0 0 180px; 
    margin-right: 10px; /* Space between label and input */
    white-space: nowrap; /* Ensures the label text stays in one line */
  }
  
  .formInput, .nestedFormGroup .formInput {
    flex: 1; /* Input takes the rest of the space */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .nestedFormGroup {
    margin-left: 20px; /* Indent nested fields */
  }

  .resultView .ant-tabs-nav {
    padding-left: 20px !important; /* Adjust this value as needed */
  }
  
  .textContentView pre {
    white-space: pre-wrap; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word; /* Standard CSS property for word-wrap */
  }
  
  .rightToLeftText {
    direction: rtl; /* Right-to-left text direction */
  }
  