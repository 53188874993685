.documentView {
    display: flex;
    width: 100vw;
    height: 80vh;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); 
    margin-top: 0;
}
  
.mpContainer, .resultView {
    flex: 1;
    overflow: hidden; 
}

.mpContainer{
  border: 1px solid #ccc;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
}

.audio {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); 
  border-radius: 32px;
  width: 50%;
  margin: auto; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
  
@media (max-width: 810px) {
    .documentView {
      flex-direction: column; 
    }
  
    .mpContainer, .resultView {
      flex-basis: 50%; 
    }
}

.resultView {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-right: 20px;
  padding-left: 20px;
  overflow-y: auto;
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); */
}


/* miniNav styling */

.miniNav {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px; /* Increased padding for better spacing */
  background-color: #f9f9f9; /* Light and subtle background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 100vw;
  margin-bottom: 0;
}

.miniNav button {
  padding: 8px 15px;
  border: none;
  background-color: #1677ff; /* Primary button color */
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; 
}

.miniNav button:hover {
  background-color: #0056b3; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.approved {
  background-color: #3eba5b !important; 
}

.approved:hover {
  background-color: #34a74f !important;
}

.not_approved {
  margin-left: 10px;
  background-color: #e45462 !important; 
}

.not_approved:hover {
  background-color: #c34551 !important; 
}

.rightButtons {
  display: flex;
}
