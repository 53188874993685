.form {
    text-align: left;
    width: 400px; 
    margin: auto; 
    padding: 20px;
    background-color: white; 
    border-radius: 8px; 
    align-items: center; 
    gap: 10px; 
    margin-bottom: 15px; 
}

.optionalIndicator{
    color: red;
}

.label {
    display: block;
    margin-bottom: 10px;
    color: #333; /* Darker color for text */
    font-weight: bold; /* Make label text bold */
    text-align: left;
}

.processor {
    align-items: center; 
    gap: 10px; 
    margin-bottom: 15px; 
}

.input_field, .select_field {
    width: 100%; /* Full width */
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 4px; /* Slightly rounded corners */
    box-sizing: border-box; /* Box-sizing property */
}

.button {
    width: fit-content; /* Full width */
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    background-color: #0BACCF; 
    color: white; 
    cursor: pointer;
    transition: background-color 0.3s; 
}

.deletebutton{
    border: none;
    border-radius: 4px;
    background-color: #0BACCF; 
    color: white;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s; 
}

.button:hover {
    background-color: #0babcfe1; /* Darker shade on hover */
}